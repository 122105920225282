const defaultOptions = {
    canStart: true,
    delay: 0,
    interval: 25,
    duration: 0
}

export default {
    name: 'typewriter',
    component(text = '', options = defaultOptions) {
        // @ts-expect-error setup Alpine types correctly?
        const el = this.$el
        const _options = { ...defaultOptions, ...options }

        return {
            text,
            char: -1,
            observer: null as IntersectionObserver | null,
            started: false,
            ended: false,
            canStart: _options.canStart,
            play() {
                if (this.started || !this.canStart) return
                setTimeout(() => {
                    this.started = true

                    const interval = _options.duration ? _options.duration / (text.length) : _options.interval

                    const timer = setInterval(() => {
                        this.char++;
                        if (this.char === text.length) {
                            clearInterval(timer);
                            this.observer?.disconnect()
                            this.ended = true
                        }
                    }, interval);
                }, _options.delay)
            },
            init() {
                if (this.canStart) {
                    this.observer = new IntersectionObserver((entries) => {
                        entries.forEach((entry) => {
                            if (entry.isIntersecting) {
                                this.play()
                            }
                        })
                    }, {
                        rootMargin: '0px',
                        threshold: 0.25
                    })

                    this.observer.observe(el)
                }
            }
        }
    }
}
