// <div x-data="scrollRatioHandler($el, callback, options)">

import { getDevice } from '@/utils/devices'
import { offsetTop } from '@/utils/offset'

const defaultOptions = {
    mediaQueries: ['desktop', 'tablet', 'mobile'],
    relativeRatio: false,
    offset: [
        [0.5, 0.5],
        [1, 0]
    ],
    ease: 1,
    easingFunction: value => value
}

export default {
    name: 'useScrollRatio',
    callback: (el, { cleanup }) => {
        const component = el._x_dataStack[0]
        return (ref, callback, _options = {}) => {
            const options = { ...defaultOptions, ..._options }

            let scrollY = window?._lenis?.actualScroll || 0
            let enabled = false

            const viewport = {
                width: window.innerWidth,
                height: window.innerHeight,
                documentHeight: document.documentElement.scrollHeight
            }

            let ratio = 0
            let top = 0
            let width = 0
            let height = 0
            let immediate = false

            const fromOffset = options.offset[0]
            const fromOffsetElement = fromOffset[0]
            const fromOffsetViewport = fromOffset[1]
            const toOffset = options.offset[1]
            const toOffsetElement = toOffset[0]
            const toOffsetViewport = toOffset[1]

            let device: string | null = null

            component.$useResizeObserver(ref, e => {
                width = e.borderBoxSize?.[0]?.inlineSize || ref.offsetWidth
                height = e.borderBoxSize?.[0]?.blockSize || ref.offsetHeight

                immediate = true
            })

            component.$useWindowResize(e => {
                viewport.width = e.innerWidth
                viewport.height = e.innerHeight
                viewport.documentHeight = e.documentHeight
                device = getDevice(e.innerWidth)

                enabled = Boolean(device && options.mediaQueries.includes(device))
                if (enabled) {
                    top = ref ? offsetTop(ref) : 0
                }
                immediate = true
            }, true)

            const handleScroll = () => {
                window._lenis.on('scroll', () => {
                    scrollY = window._lenis.actualScroll || 0
                })
            }

            handleScroll()
            component.$watch('$store.global.hasBodyLock', bodylock => {
                handleScroll()
            })

            component.$useRaf(() => {
                if (enabled && ref && viewport.documentHeight && height) {
                    const easing = immediate ? 1 : options.ease || 1
                    const fromY = fromOffsetElement * height - fromOffsetViewport * viewport.documentHeight
                    const toY = toOffsetElement * height - toOffsetViewport * viewport.documentHeight
                    const dY = toY - fromY
                    ratio += ((scrollY - (fromY + top)) / dY - ratio) * easing

                    immediate = false

                    callback(options.easingFunction ? options.easingFunction(ratio) : ratio, {
                        easing,
                        originalRatio: ratio,
                        scrollY,
                        top,
                        height,
                        width,
                        viewport
                    })
                } else {
                    ratio = 0
                    callback(ratio, {
                        originalRatio: ratio,
                        scrollY,
                        top,
                        height,
                        width,
                        viewport
                    })
                }
            })

            cleanup(() => {
                ratio = 0
                callback(ratio, {
                    originalRatio: ratio,
                    scrollY,
                    top,
                    height,
                    width,
                    viewport
                })
                window._lenis.off('scroll')
            })
        }
    }
}