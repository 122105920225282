export interface FaqComponent {
  updateVisibility(): unknown;
  currentCategory: string;
  setCategory: (category: string) => void;
}

export default {
  name: 'faq',
  component(): FaqComponent {
    return {
      currentCategory: '',
      setCategory(category: string) {
        this.currentCategory = category;
        this.updateVisibility();
      },
      updateVisibility() {
        // @ts-expect-error: $el is added by Alpine.js
        const el = this.$el as HTMLElement;

        el.querySelectorAll<HTMLElement>('[data-faq-category]').forEach(category => {
          category.classList.toggle('active', category.dataset.faqCategory === this.currentCategory);
        });

        el.querySelectorAll<HTMLElement>('[data-faq-content]').forEach(content => {
          content.classList.toggle('hidden', content.dataset.faqContent !== this.currentCategory);
        });
      }
    };
  },
};
