import {precision} from "@/utils/maths"

export default {
  name: 'essential',
  component() {
    return {
      init() {
        // @ts-expect-error: $el is added by Alpine.js
        const el = this.$el as HTMLElement
        // @ts-expect-error: $refs is added by Alpine.js
        const image = this.$refs.image as HTMLElement
        const lines = Array.from(el.querySelectorAll('[data-line-svg]')) as HTMLElement[]

        const handleResize = () => {
          const imageRect = image.getBoundingClientRect()
          const imageCenter = imageRect.left + imageRect.width / 2

          const widths = lines.map(line => {
            const lineRect = line.getBoundingClientRect()
            const isLeft = Boolean(lineRect.left < imageCenter)

            let lineLength = 0
            if (isLeft) {
              const lineRight = lineRect.left
              lineLength = Math.abs(lineRight - imageCenter)
            } else {
              const lineLeft = lineRect.right
              lineLength = Math.abs(lineLeft - imageCenter)
            }

            return precision(lineLength)
          })

          widths.forEach((width, index) => {
            lines[index].style.width = `${width}px`
          })
        }

        const handleScroll = (ratio: number) => {
          if (ratio > 0 && ratio < 1) {
            const eRatio = easeOutExpo(ratio)
            el.style.setProperty('--progress', (1 - eRatio).toString())
          }
        }

        // @ts-expect-error: $useWindowResize is added by Alpine.js
        this.$useWindowResize(handleResize)
        // @ts-expect-error: $useScrollRatio is added by Alpine.js
        this.$useScrollRatio(image, handleScroll, {
          offset: [[0, 1], [1, 0.5]],
        })

        handleResize()
      }
    }
  }
}

function easeOutExpo(x: number): number {
  return x === 1 ? 1 : 1 - 2 ** (-10 * x);
}
