const TARGET_VARIANT_ID = 49693618307336;
const MAX_QUANTITY = 2;

/**
 * Ensures only one instance of a specific variant exists in the cart with a maximum quantity of 2.
 * If multiple instances or higher quantities are found, it adjusts the cart accordingly.
 * 
 * @returns {Object|undefined} Cart update request object if changes are needed, undefined otherwise.
 */
const ensureMaxQuantity = () => {
  // Type assertion to handle potential 'liquidAjaxCart' property missing on Window
  const cart = (window as any).liquidAjaxCart.cart;

  // Find all line items matching the target variant ID
  const matchingLineItems = cart.items.filter(item => item.variant_id === TARGET_VARIANT_ID);

  // No action needed if no matching items or single item with correct quantity
  if (matchingLineItems.length === 0 || (matchingLineItems.length === 1 && matchingLineItems[0].quantity <= MAX_QUANTITY)) {
    return undefined;
  }

  // Prepare updates object for cart mutation
  const updates = matchingLineItems.reduce((acc, item, index) => {
    acc[item.key] = index === 0 ? MAX_QUANTITY : 0;
    return acc;
  }, {});

  // Return cart update request
  return {
    requests: [{
      type: "update",
      body: {updates}
    }],
  };
};

// Register the mutation function with Liquid Ajax Cart
(window as any).liquidAjaxCart.conf('mutations', [ensureMaxQuantity]);
