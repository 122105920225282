import '@/lenis'
import '@/cart'

import Alpine from 'alpinejs'
import AlpineCollapse from '@alpinejs/collapse'
import AlpineFocus from '@alpinejs/focus'
import AlpineMorph from '@alpinejs/morph'
import AlpineIntersect from '@alpinejs/intersect'

import {shopifyFormatCurrency} from '@/utils/currency'
import AlpineGlobals from '@/alpine/index'

class App {
  modules: any

  constructor() {
    setTimeout(this.init, 0)
  }

  init = () => {
    // Register and initialize AlpineJS
    window.Alpine = Alpine

    Alpine.plugin([AlpineCollapse, AlpineFocus, AlpineMorph, AlpineIntersect])

    Alpine.magic('shopifyFormatCurrency', () => shopifyFormatCurrency)

    AlpineGlobals.register(Alpine);

    Alpine.start()
  }
}

window._app = new App()
