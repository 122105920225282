import Vlitejs from 'vlitejs';

export default {
    name: 'videoPlayer',
    component(props) {
        const { srcs, widths } = props
        const entries = srcs.map((src, i) => ({ src, width: widths[i] })).sort((a, b) => a.width - b.width)

        if (!srcs?.length) {
            console.warn('Video srcs are required')
            return null
        }

        if (srcs.length !== widths.length) {
            console.warn('Video loop srcs & widths length are not equal')
        }

        const playerRef = { current: undefined, focusTo: this.$el.querySelector('.v-playPauseButton') }

        return {
            player: playerRef.current,
            intersectionObserver: undefined,
            src: '',

            init() {
                const instance = new Vlitejs(this.$el, { provider: 'html5' })
                playerRef.current = instance.player

                if (entries.length > 1) {
                    this.$useResize(this.$el, e => {
                        const bounds = e.boundingClientRect
                        this.updatePlayer(bounds.width)
                    })
                } else {
                    this.updatePlayer()
                }
            },

            updatePlayer(width = 0, start = false) {
                const _w = width || this.$el.getBoundingClientRect().width
                const closestEntry = entries.reduce((acc, curr) => (Math.abs(curr.width - _w) <= Math.abs(acc.width - _w) ? curr : acc));
                const src = closestEntry?.src

                if (src !== this.src) {
                    this.src = src
                }

                if (start) {
                    playerRef.current?.play?.()
                    playerRef.focusTo?.focus?.()
                }
            },

            pausePlayer() {
                playerRef.current?.pause?.()
            },

            destroy() {
                playerRef.current?.destroy()
            }
        }
    }
}
