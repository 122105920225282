export default {
	name: 'localizationForm',
	component() {
		const form = this.$el.querySelector('form')
		return {
			init() {
				form.addEventListener('change', this.handleFormChange)
			},
			destroy() {
				form.removeEventListener('change', this.handleFormChange)
			},
			handleFormChange() {
				form.submit()
			}
		}
	}
}
