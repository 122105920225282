
export default {
    name: 'science',
    component() {
        // @ts-expect-error setup Alpine types correctly?
        const el = this.$el
        const sectionTitles: HTMLElement[] = Array.from(el.querySelectorAll('[data-science-title]'))
        const bgLayers: HTMLElement[] = Array.from(el.querySelectorAll('[data-bg-sticky-spacer]')).slice(0, sectionTitles.length) as HTMLElement[]

        const handleResize = () => {
            const elRect = el.getBoundingClientRect()
            const sectionTitleRects = sectionTitles.map(section => section.getBoundingClientRect())
            const sectionHeights = [] as number[]
            const scrollY = window.scrollY

            for (let i = 0; i < sectionTitleRects.length; i++) {
                const rect = sectionTitleRects[i]
                const nextRect = sectionTitleRects[i + 1] || { top: 0 }

                let h = Math.abs(nextRect.top - rect.top)

                if (i === 0) {
                    h = nextRect.top + scrollY
                }

                if (i === sectionTitleRects.length - 1) {
                    h = elRect.bottom - rect.top
                }

                sectionHeights.push(h)
            }

            sectionTitles.forEach((section, index) => {
                const bgParent = bgLayers[index]
                if (bgParent) {
                    bgParent.style.height = `${sectionHeights[index]}px`
                }
            })
        }

        const titles = sectionTitles.map(title => ({
            inMenu: typeof title.dataset.scienceMenuItem !== 'undefined',
            title: (title.querySelector('[data-science-title-inner]') as HTMLElement)?.innerText,
            id: title.id
        })).filter(section => section.inMenu)

        return {
            sectionTitles: titles,
            bgLayers,
            init: () => {
                // @ts-expect-error setup Alpine types correctly?
                this.$useResizeObserver(el, handleResize)
                handleResize()
            }
        }
    }
}
